 .status .MuiFormControl-root {width: fit-content;}
.formlabel {margin: 10px 0;}
.Formsubmit_button button {font-size: 24px; text-transform: inherit!important; color: var(--white);background: var(--blue2);padding: 5px 10px; min-width: 180px;display: block;font-weight: 600;margin-right: 15px;}
.Formsubmit_button button:hover {background: var(--blue2);}
.MuiFormHelperText-root {color: var(--red) !important;}
.Formsubmit_button {display: flex;align-items: center;}
.listCount {text-align: center; padding-top: 10px;}


.adminformbody .formInline{display:flex;align-items:stretch;justify-content:space-between;flex-wrap:wrap}
.adminformbody .formInline label{color:#000}

.adminformbody{ position: relative; z-index: 1;}
.adminformbody .formInline .inputBlock{flex:1 0 30%;margin:10px .3%}
.adminformbody .formInline .inputBlock4line{flex:1 0 24%;margin:10px .3%}
.adminformbody .formInline .inputBlock3line{flex:1 0 30%;margin:10px .3%}
.adminformbody .formInline .inputBlock2line{flex:1 0 48%;margin:10px .3%}
.backend_addform .inputBlock1line .MuiFormControl-root {width: 100%;margin:5px 0;}



.adminformbody .formInline .inputBlock1line{flex:0 0 100%;margin:5px .3%}

.folder_view_con .adminformbody .formInline .inputBlock1line{flex:0 0 100%;margin:5px .3%; margin-left: 10px;}
.adminformbody .formInline .inputBlock .MuiFormControl-root.MuiTextField-root{background-color:#fff;width:100%;margin-bottom:0;border:solid 1px #ccc;font-size: 14px;   color: #333;   font-family: "Poppins", sans-serif;}
.adminformbody .formInline .inputBlock textarea {background-color:#fff;width:100%;margin-bottom:0;border:solid 1px #ccc!important;height:100px!important; padding: 10px; resize: none; outline: none!important; font-size: 14px; color: #333;    font-family: "Poppins", sans-serif; }

.adminformbody .formInline .color, .adminformbody .formInline .color > div {margin-top: 8px;}

.adminformbody .formInline .inputBlock .MuiFormControl-root.MuiTextField-root input,.adminformbody .formInline .inputBlock .MuiSelect-select{padding:12px 14px;   font-family: "Poppins", sans-serif;  font-size: 14px; color: #333; }
.adminformbody .formInline .inputBlock .MuiFormControl-root .MuiInputLabel-root{top:-4px;  color: #a9a9a9; margin: 0;}
.adminformbody .formInline .inputBlock .MuiSelect-select{background-color:#fff;width:100%;margin-bottom:0;border:solid 1px #ccc; font-size: 14px; min-height: 32px;     padding: 6px 14px!important;  border-radius: 0px;  color: #333;   font-family: "Poppins", sans-serif;}


.adminformbody .formInline .custom_btn {width: 100%;}
.adminformbody .formInline .custom_btn > button {margin-top: 8px;}
.adminformbody .formInline .custom_btn .data_container {width: 60%;margin-top: 25px;padding: 23px 10px;margin-left: auto;margin-right: auto;}
.adminformbody .formInline .custom_btn .data_container .data_label {text-align: center;width: auto;margin: 0 auto;background: #0569ad;color: #fff;padding: 10px;font-size: 30px;line-height: 30px;}
.adminformbody .formInline .custom_btn .data_container .data_set {width: calc(95% + 14px);}
.adminformbody .formInline .custom_btn .data_container .data_set .wrapper_show_html {display: flex;align-items: center;padding: 10px 15px;background: #d7d7d74f;border-radius: 5px;}
.adminformbody .formInline .custom_btn .data_container .data_set .wrapper_show_html:not(:first-child) {margin-top: 10px;}
.adminformbody .formInline .custom_btn .data_container .data_set .wrapper_show_html span {display: block;}
.adminformbody .formInline .custom_btn .data_container .data_set .wrapper_show_html span img, 
.adminformbody .formInline .custom_btn .data_container .data_set .wrapper_show_html span iframe, 
.adminformbody .formInline .custom_btn .data_container .data_set .wrapper_show_html span embed, 
.adminformbody .formInline .custom_btn .data_container .data_set .wrapper_show_html span video {width: 100%;max-height: 700px;display: block;}
.adminformbody .formInline .custom_btn .data_container .data_set .wrapper_show_html span:nth-child(1) {font-size: 22px;font-weight: 600;width: fit-content;}
.adminformbody .formInline .custom_btn .data_container .data_set .wrapper_show_html span:nth-child(2) {margin-left: 20px;width: 79%;}
.adminformbody .formInline .Formsubmit_button {margin-top: 15px;}
.adminformbody .formInline .custom_btn .data_container .data_block .data_btn_container {padding: 10px;background: #d7d7d74f;border-radius: 5px;}
.adminformbody .formInline .custom_btn .data_container .data_block .data_btn_container button:hover {color: rgb(51, 43, 43);}


.adminformbody .upload_data_wrpr > h2 {padding: 10px 20px;color: #fff;display: inline-block;border-radius: 8px;background: linear-gradient(360deg, rgba(5,105,173,1) 0%, rgba(75,160,202,1) 100%);}


.bookingModal .calendar_modal_field {display: flex;flex-wrap: wrap;}
.bookingModal .calendar_modal_field .addemail {flex: 1 0 48%;margin:10px .5%}
.bookingModal .calendar_modal_field .addemailBTN {bottom: 10px;}

/* <---------------Modal Common CSS--------------> */

.dialog .MuiDialog-container .MuiPaper-root h2 {text-align: center;width: 92%;margin: 0 auto;background: #0569ad;color: #fff;margin-top: 25px;padding: 10px!important;font-size: 30px;line-height: 30px;}
.dialog .MuiDialog-container .MuiPaper-root .MuiDialogContent-root {padding: 20px;}
.dialog .formInline label {color: #000;top: -5px;} 
.dialog .formInline .inputBlock {margin: 10px 0.3%;}
.dialog .formInline .inputBlock .MuiFormControl-root{width:100%;margin:0}
.dialog .formInline .inputBlock .MuiOutlinedInput-root{border-radius:0}
.dialog .formInline .inputBlock .MuiOutlinedInput-notchedOutline{border:0!important}
.dialog .formInline .inputBlock .MuiInputLabel-shrink{display:none!important}
.dialog .formInline .inputBlock .MuiFormControl-root.MuiTextField-root {background-color: #fff;width: 100%;border: solid 1px #ccc;color: #333;}
.dialog .formInline .inputBlock .MuiFormControl-root.MuiTextField-root input {padding: 12px 10px;font-family: "Poppins", sans-serif;font-size: 14px;color: #333;}
.dialog .formInline .inputBlock .MuiFormControl-root textarea {background-color: #fff;width: 100%;margin-bottom: 0;border: solid 1px #ccc!important;height: 100px!important;padding: 10px;resize: none;outline: none!important;font-size: 14px;color: #333;font-family: "Poppins", sans-serif;}
.dialog .formInline .submitbtnsection {display: flex;justify-content: center;align-items: center;margin-top: 20px;}
.dialog .formInline .submitbtnsection button {width: fit-content;font-size: 28px;line-height: 28px;padding: 15px 20px;}
.dialog .formInline .upload_data_wrpr .image_container .content_wrpr, .dialog .formInline .upload_data_wrpr .image_container .box {width: 45%;}
.dialog .formInline .upload_data_wrpr .image_container .content_wrpr h1 button, .dialog .formInline .upload_data_wrpr .image_container .box .image_name {font-size: 14px;line-height: 18px;word-break: break-word;}
.dialog .formInline .upload_data_wrpr .image_container .btn_container button, .upload_data_wrpr .upload-container .image_container .btn_container button {margin: 5px;}
.dialog .formInline .upload_data_wrpr .image_container .btn_container {padding: 20px 0 0;}
.dialog .formInline .upload_data_wrpr .blog_img_wrpr {margin-bottom: 10px;justify-content: center;}
.dialog .formInline .upload_data_wrpr .blog_img_wrpr h1 {font-size: 24px;line-height: 30px;}
.dialog .formInline .upload_data_wrpr > h2 {margin-top: 0;font-size: 22px;line-height: 24px;width: 86%;background: linear-gradient(360deg, rgba(5,105,173,1) 0%, rgba(75,160,202,1) 100%);}

.dialog .MuiDialog-container .MuiDialog-paper {overflow-y: unset;max-width: 90% !important;}

.adminformbody .formInline .upload_data_wrpr .blog_img_wrpr {background-color: rgb(0 0 0 / 12%);width: fit-content;padding: 10px 20px;border-radius:8px ;}
.adminformbody .formInline .upload_data_wrpr .blog_img_wrpr #list h1 {font-size: 24px;font-weight: 500;}

.BoxMui_modal .program_block, .BoxMui_modal .chip_block {overflow: unset;max-width: unset;}

/* <---------------Modal Common CSS--------------> */

/* <-------------Training Material Preview Modal CSS-------------------> */

.onlymaterial_BoxMui_modal {width: 800px !important;max-height: 500px;}
.onlymaterial_BoxMui_modal .ModalBox_scroll .listflexblock {overflow: hidden;overflow-y: auto;max-height: 370px;}
.onlymaterial_BoxMui_modal .listflexblock li strong {width: 22%;}
.onlymaterial_BoxMui_modal .listflexblock li span {width: 75%;}
.onlymaterial_BoxMui_modal .listflexblock li span div:not(:first-child) {margin-top: 10px;}
.onlymaterial_BoxMui_modal .listflexblock li span div div:not(:first-child) {margin-top: 8px;}
.onlymaterial_BoxMui_modal .listflexblock li span div #uploadFile img, .onlymaterial_BoxMui_modal .listflexblock li span div #uploadFile video {display: block;width: 100%;}
.onlymaterial_BoxMui_modal .listflexblock li span div #uploadFile embed {width: 100%;height: inherit;}
.onlymaterial_BoxMui_modal .listflexblock li span div #uploadFile audio, .onlymaterial_BoxMui_modal .listflexblock li span div #uploadFile iframe {width: 100%;}
.onlymaterial_BoxMui_modal .listflexblock li span div #uploadFile button {padding: 8px 16px;border-radius: 8px;border: none;background-color: var(--orange);cursor: pointer;font-size: 14px;color: var(--white);margin-top: 10px;display: block;}

/* <-------------Training Material Preview Modal CSS-------------------> */


/* <---------------Form Text Specific CSS--------------> */

.first_letter_caps .MuiFormControl-root .MuiTextField-root .MuiInputBase-root input {text-transform: capitalize;}
.custom_role_user .MuiFormControl-root .MuiTextField-root .MuiOutlinedInput-root {padding: 0;} 

/* <---------------Form Text Specific CSS--------------> */


/* <-------------Other Preview Modal CSS-------------------> */

.other_BoxMui_modal .listflexblock .divul {width: 100%;}

/* <-------------Other Preview Modal CSS-------------------> */


/* <-------------Chip Block for Modal CSS-------------------> */

.BoxMui_modal .listflexblock .divul .chip_block, .BoxMui_modal .listflexblock .divul .program_block {max-width: unset;overflow: unset;white-space: pre-wrap;}

/* <-------------Chip Block for Modal CSS-------------------> */



/* <-------------All ADD Edit form helpertext CSS-------------------> */

/* .adminformbody .inputBlock .MuiTextField-root .MuiFormHelperText-root.Mui-error {} */

/* <-------------All ADD Edit form helpertext CSS-------------------> */



/* <--------------User Listing Drawer Modal CSS------------> */

.MuiDrawer-modal .MuiDrawer-paper .formInline .submitbtnsection {justify-content: center;}
.MuiDrawer-modal .MuiDrawer-paper .formInline .submitbtnsection button {color: #fff;background: #1976d2;min-width: 64px;padding: 6px 16px;margin: 5px;border: none;font-size: 20px;font-weight: 500;width: initial;box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);}
.MuiDrawer-modal .MuiDrawer-paper .formInline .submitbtnsection button .MuiButton-endIcon {display: none;}
.MuiDrawer-modal .list_search {margin-bottom: 0;padding: 0;}
.MuiDrawer-modal .formWrapperClass {padding: 20px 30px;}
.MuiDrawer-modal .list_search .formCls {margin-top: 20px;}

/* <--------------User Listing Drawer Modal CSS------------> */


.statusinput{ display: flex;  align-items: center; }

.statusinput label{ margin-right: 15px; overflow: inherit;}

.statusinput .MuiCheckbox-root{ padding:0 5px 0 10px;}

.adminformbody .formInline .inputBlock .MuiFormControl-root{width:100%;margin:0}
.adminformbody .formInline .inputBlock .MuiOutlinedInput-root{border-radius:0}
.adminformbody .formInline .inputBlock .MuiOutlinedInput-notchedOutline{border:0!important}
.adminformbody .formInline .inputBlock .MuiInputLabel-shrink{display:none!important}
/* .adminformbody .formInline .usertypebox .MuiSelect-select{padding:8px 14px;height:31px; }
.adminformbody .formInline .usertypebox .MuiFormControl-root{background-color:#fff;width:100%;margin:0;border:solid 1px #ccc} */
.adminformbody .formInline .passwordinput .MuiFormControl-root{background-color:#fff;width:100%;margin:0;border:solid 1px #ccc}
.adminformbody .formInline .passwordinput .MuiFormControl-root input{padding:12px 14px}
/* .adminformbody .formInline .addressbox{flex:0 0 100%;margin:10px .3%} */
/* .adminformbody .formInline .addressbox textarea{background-color:#fff;width:calc(100% - 0.5%);margin-bottom:0;border:solid 1px #ccc!important;resize:none;height:50px!important;padding:10px 1%;outline:none!important;} */
.adminformbody .css-1wc848c-MuiFormHelperText-root .Mui-error{color:#d32f2f;bottom:-2px;font-size:11px;position:absolute}
.adminformbody .MuiFormHelperText-root{color:#d32f2f;bottom:-18px;font-size:11px;position:absolute;margin: 0;}
.Formsubmit_button{  width: 100%;}
.adminformbody input:-webkit-autofill,.adminformbody input:-webkit-autofill:hover,.adminformbody input:-webkit-autofill:focus,.adminformbody textarea:-webkit-autofill,.adminformbody textarea:-webkit-autofill:hover,.adminformbody textarea:-webkit-autofill:focus,.adminformbody select:-webkit-autofill,.adminformbody select:-webkit-autofill:hover,.adminformbody select:-webkit-autofill:focus{border:none;-webkit-text-fill-color:#000;-webkit-box-shadow:0 0 0 1000px #fff inset;transition:background-color 5000s ease-in-out 0; }



.addedit_wrp .dataform1_wrapper::before {content: '';position: absolute;width: 170px;height: 170px;background: #ffffff; opacity: 0.5; left:-50%;bottom: -20px;border-radius: 50%;}
.addedit_wrp .dataform1_wrapper {position: relative;}
.addedit_wrp {background: url();}
.addedit_wrp .dataform1_wrapper::after {content: '';position: absolute;width: 170px;height: 170px;background: #ffffff;  opacity: 0.5; right:-50%;bottom: 230px;border-radius: 50%;}


.MuiAutocomplete-root{ margin: 0!important;}

.passwordBlock .formInline .inputBlock .MuiOutlinedInput-root {
    border-radius: 0;
    border: 1px solid #c3c3c3;
}

.statusinputBox{ display: flex;  position: relative!important; align-items: center; padding:12px 10px; justify-content: space-between; margin-bottom: 15px!important; flex: 20%!important; border: solid 1px #ccc; }

.statusinputBox label{ margin-right: 0; overflow: inherit;}

.statusinputBox .MuiCheckbox-root{ padding:0 5px 0 10px;}
.statusinputBox  .MuiFormControl-root {
    width: auto!important; display: flex; align-items: flex-end;
    margin: 0; position: unset!important;
}

.statusinputBox .MuiFormHelperText-root{ right: inherit; left:10px; bottom: -1px; margin: 0; padding: 0;}

.css-w66kx-MuiChip-root{ height: auto!important;}

/* .MuiMenu-list li.MuiMenuItem-root{ display: flex!important; text-align: left!important;} */


.LoadmoreBtn{font-size: 24px!important;
    color: var(--white)!important;
    background: var(--blue2)!important;
    padding: 5px 10px!important;
    min-width: 180px!important;
    display: block!important;
    font-weight: 600!important;
    margin: 0 auto!important; margin-top: 15px!important; text-transform: inherit!important;}

@media only screen and (max-width: 1699px) {
.addedit_wrp .dataform1_wrapper::before {left: -150px;bottom: 40px;width: 120px;height: 120px;}


}

@media only screen and (max-width: 1680px) {
    .addedit_wrp .dataform1_wrapper::after {right: -210px;}

    .adminformbody .formInline .custom_btn .data_container .data_set .wrapper_show_html span:nth-child(1) {font-size: 20px;font-weight: 500;}
    .adminformbody .formInline .custom_btn .data_container .data_set .wrapper_show_html span:nth-child(2) {width: 76%;}
}
@media only screen and (max-width: 1400px) {
    .adminformbody .formInline .custom_btn .data_container .data_set .wrapper_show_html span:nth-child(1) {font-size: 18px;}
    .adminformbody .formInline .custom_btn .data_container .data_set .wrapper_show_html span:nth-child(2) {margin-left: 15px;}
}
@media only screen and (max-width: 1340px) {
.addedit_wrp .dataform1_wrapper::after {right: -150px;bottom: 140px;width: 120px;height: 120px;}
}

@media only screen and (max-width: 1280px) {
    .adminformbody .formInline .custom_btn .data_container .data_set .wrapper_show_html span:nth-child(2) {
        width: calc(75% - 10px);
    }
}


@media only screen and (max-width: 1199.98px) {
 .adminformbody .formInline{display:block}
 .statusinputBox{     display: inline-flex; width: calc(25% - 7px);}
 .addedit_wrp .dataform1_wrapper::after, .addedit_wrp .dataform1_wrapper::before {display: none;}

 .adminformbody .formInline .custom_btn .data_container {width: 100%;margin-bottom: 25px; }
 .adminformbody .formInline .custom_btn .data_container .data_set .wrapper_show_html:nth-of-type(2) {flex-direction: column;}
 .adminformbody .formInline .custom_btn .data_container .data_set .wrapper_show_html:nth-of-type(2) span:nth-child(2) {margin-left: 0;margin-top: 8px;width: 100%;}
 .MuiDrawer-modal .formWrapperClass {padding: 20px;}
}

@media only screen and (max-width: 991.98px) {
    .addedit_wrp .dataform1_wrapper::before { display: none;}
    .addedit_wrp .dataform1_wrapper::after { display: none;}
    .statusinputBox{width: calc(50% - 7px);}
    .adminformbody .formInline .custom_btn .data_container .data_set .wrapper_show_html {padding: 8px 10px;}
    .adminformbody .formInline .custom_btn .data_container .data_block .data_btn_container {padding: 5px;}
}

@media only screen and (max-width: 848px) {
    .onlymaterial_BoxMui_modal .listflexblock li strong {width: 20%;}
    .onlymaterial_BoxMui_modal .listflexblock li span {width: 78%;}
}


@media only screen and (max-width: 800.98px) {
    .Formsubmit_button{ text-align: center; display: block;}
 .Formsubmit_button button{ font-size: 22px;   min-width: 200px; padding: 5px; margin: 5px;   display: inline-block;}
 
}
@media only screen and (max-width: 648px) {
    .onlymaterial_BoxMui_modal .listflexblock li strong {width: 33%;}
    .onlymaterial_BoxMui_modal .listflexblock li span {width: 67%;}
}
@media only screen and (max-width: 580.98px) {
    .statusinputBox{   width:auto; display: flex;}
}

@media only screen and (max-width: 540.98px) {
 
    .dialog .formInline .upload_data_wrpr > h2 {font-size: 22px;line-height: 24px;}
}
@media only screen and (max-width: 523px) {
    .dialog .formInline .upload_data_wrpr .image_container .box {margin-top: 20px;}
    .dialog .formInline .upload_data_wrpr .image_container .content_wrpr, .dialog .formInline .upload_data_wrpr .image_container .box {width: 100%;}
    .dialog .formInline .upload_data_wrpr .image_container .btn_container button {margin: 4px;}
    .dialog .formInline .submitbtnsection button {font-size: 24px;line-height: 24px;}

    .adminformbody .formInline .custom_btn .data_container .data_block {flex-direction: column;}
    .adminformbody .formInline .custom_btn .data_container .data_set {width: 100%;}
    .adminformbody .formInline .custom_btn .data_container .data_set .wrapper_show_html:not(:first-child) {margin-top: 5px;}
    .adminformbody .formInline .custom_btn .data_container .data_block .data_btn_container {flex-direction: row;margin-left: 0;margin-top: 5px;}
    .adminformbody .formInline .custom_btn .data_container .data_set .wrapper_show_html span:nth-child(2) {margin-left: 10px;}

    .adminformbody .formInline .custom_btn .data_container .data_label {font-size: 24px;}
    .adminformbody .formInline .custom_btn .data_container .data_set .wrapper_show_html:first-child span:nth-child(2) {width: auto;}
}
@media only screen and (max-width: 499px) {
    .bookingModal .calendar_modal_field .addemail {flex: 1 0 98%;margin: 3px .2%;}
    .bookingModal .calendar_modal_field .addemailBTN {bottom: 3px;}
}
@media only screen and (max-width: 420px) {
    .dialog .MuiDialog-container .MuiPaper-root h2 {font-size: 26px;line-height: 26px;}
    .dialog .formInline .submitbtnsection button {font-size: 24px;line-height: 24px;padding: 12px 20px;}
    .dialog .MuiDialog-container .MuiPaper-root h2 {font-size: 20px;line-height: 24px;}

    .adminformbody .formInline .custom_btn .data_container .data_label {font-size: 20px;}
}

@media only screen and (max-width: 398px) {
    .onlymaterial_BoxMui_modal .listflexblock li strong {width: 38%;}
    .onlymaterial_BoxMui_modal .listflexblock li span {width: 58%;}

    .adminformbody .formInline .custom_btn .data_container .data_label {font-size: 18px;}
    .adminformbody .formInline .custom_btn .data_container .data_set .wrapper_show_html span:nth-child(1) {font-size: 16px;}
}