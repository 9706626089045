 
.rolelist_mainwrp_Table{background: #fff;
    border-radius: 8px; display: block;
    box-shadow: 4px 4px 25px hsla(0,1.9%,42%,0.15);   position: relative; padding: 25px; margin-bottom: 25px;}   

 .hideTop .rolelist_mainwrp{ margin-top: 25px;}   

.rolelist_mainwrp .MuiTableRow-root .MuiTableCell-head:last-child {width: 200px;}
.rolelist_mainwrp  .stackPagination {padding: 20px 0; }

.rolelist_mainwrp table{ border: none;}
.rolelist_mainwrp .MuiTableContainer-root{ box-shadow: none!important; border-radius: 0px; box-shadow: none!important;}
.lastLogin_Info .rolelist_mainwrp .MuiTableContainer-root {overflow-y: scroll;overflow-x: hidden;height: 500px;}
.lastLogin_Info .loginInfoData .replayicon {position: absolute;right: 15px;top: 40%;transform: translateY(-50%);color: #fff;}

.rolelist_mainwrp  .MuiPaper-elevation{box-shadow: none!important;}

.rolelist_mainwrp_Table .MuiTableContainer-root th{ border: solid 1px #ccc!important; padding:4px 8px; text-align: left; font-size: 16px; font-weight: 600;} 
.rolelist_mainwrp_Table .MuiTableContainer-root td{ border: solid 1px #ccc!important;  padding:4px 8px; text-align: left; font-size: 16px;}  
.rolelist_mainwrp_Table .MuiTableContainer-root th svg{ color: #000!important;}
/* .extra_icon .MuiTableContainer-root td button{padding: 6px;} */
.rolelist_mainwrp_Table .MuiTableContainer-root td img{ width: 180px; cursor: pointer;}

.rolelist_mainwrp_Table .MuiTableContainer-root tr{ position: relative;}
.rolelist_mainwrp_Table .MuiTableContainer-root tr .MuiLinearProgress-root{ position: absolute; left: 0; width: 100%;}
 
.rolelist_mainwrp  .css-pjjt8i-MuiToolbar-root{ margin: 0; min-height: inherit; padding: 0;}

.rolelist_mainwrp  .css-pjjt8i-MuiToolbar-root .MuiTypography-h6{ margin: 0 0 15px 0;   
    padding:0 0 0 10px;
    font-size: 18px;
    color: #333;
    font-weight: 500;
    border-left: solid 5px var(--blue2);}

    .rolelist_mainwrp  .css-1rv1kxc {
        min-height: inherit;
        padding: 0!important;
    }

    .rolelist_mainwrp .MuiToolbar-regula{
        min-height: inherit;
        padding: 0!important;
    }

    .rolelist_mainwrp .MuiTypography-h6 { 
        flex: 1 1 100%;
        margin: 0;
        padding: 0 0 0 10px;
        font-size: 18px;
        color: #333;
        font-weight: 500;
        border-left: solid 5px var(--blue2);
    }

 .listing_heading{margin: 25px 0; padding: 10px;  border-left: solid 5px  var(--blue2); background: #fff;     box-shadow: 0 5px 20px rgb(0 0 0 / 5%);
    border-radius: 5px; display: flex; flex-wrap: wrap; justify-content: space-between; align-items: center;}   

.listing_heading h1{ margin: 0; padding: 0 15px 0 0; font-size: 24px; color: #333; font-weight: 500;} 
    
 .listing_heading .addrole_btn{background: var(--blue2);
    color: #fff;
    text-decoration: none;
    font-size: 40px;
    padding:10px;
    display: block;
    text-align: center;
  
    border-radius: 50%;
    width: 50px;
    height: 50px;
    justify-content: center;
    align-items: center;
    display: flex;
     border: none; 

     cursor: pointer;   }

 

.noDataAvailable{ display: block; text-align: center; padding: 25px;}

.noDataAvailable img{ width: 200px; max-width: 100%;}


.userlisttable .MuiToolbar-regular{padding: 0!important;
    min-height: inherit!important;}
    .userlisttable .MuiToolbar-regular .MuiTypography-h6{margin: 0 0 15px 0;
        padding: 0 0 0 10px;
        font-size: 18px;
        color: #333;
        font-weight: 500;
        border-left: solid 5px var(--blue2);}
/*-----------------*/

.userlisttable {background: #fff;
    border-radius: 8px; display: block;
    box-shadow: 4px 4px 25px hsla(0,1.9%,42%,0.15);   position: relative; padding: 25px; margin-bottom: 25px;}

.userlisttable table,.userlisttable th, .userlisttable td { display: block; border: none; min-width: inherit!important;}
.userlisttable thead{ display: block; padding: 0;    background-color: var(--blue2);   }

.userlisttable tr{     display: flex;
    align-items: stretch;
    justify-content: space-between;
    flex-wrap: wrap}

.userlisttable tr th{       flex: 1 0 32%;   text-align: left;   border: solid 1px #0091ff!important;  padding: 0.5%; color: #fff; font-weight: 600;  }  
.userlisttable tr th button{ padding: 0; color: #fff;}
/* .userlisttable th:nth-of-type(1){ display: none;}
.userlisttable th:nth-of-type(2){ display: none;}
.userlisttable td:nth-of-type(1){ display: none;}
.userlisttable td:nth-of-type(2){ display: none;} */

.userlisttable tbody{ display: block; border: none; padding: 0; min-width: inherit!important;}

.userlisttable tbody tr{margin: 10px 0; border: solid 1px #ccc; }
  
.userlisttable tbody tr td{ flex: 1 0 32%;      padding: 0.5%;   font-weight: normal;  border: solid 1px #ccc!important; }
.userlisttable tbody tr td button{ padding: 8px;}

.listCount{ text-align: center;}

.userlisttable .MuiCheckbox-root{ padding: 0!important;}
.userlisttable th .MuiCheckbox-root svg{  color: #fff;}

.rolelist_mainwrp_Table .MuiCheckbox-root{ padding: 0!important;}

.css-1qgma8u-MuiButtonBase-root-MuiTableSortLabel-root svg{
    opacity: 1;
}


/******************All List Table CSS*******************/
         
         .landingPageTable .MuiTable-root .MuiTableRow-root .description {max-width: 350px;min-width: 180px;} 
         .landingPageTable .MuiTable-root .MuiTableRow-root .name {max-width: 250px;min-width: 120px;}
         .landingPageTable .MuiTable-root .MuiTableRow-root .type {max-width: 150px;min-width: 80px;}

         .trainingdata .MuiTable-root .MuiTableRow-root .training_name, 
         .trainingdata .MuiTable-root .MuiTableRow-root .training_parent_name {max-width: 240px;min-width: 160px;}
         .trainingdata .MuiTable-root .MuiTableRow-root .program_names {max-width: 280px;min-width: 240px;}

         .tableDataQuiz .MuiTable-root .MuiTableRow-root .question, .tableDataQuiz .MuiTable-root .MuiTableRow-root .lesson_names,
         .tableDataQuiz .MuiTable-root .MuiTableRow-root .training_name {max-width: 210px;min-width: 130px;}
         .tableDataQuiz .MuiTable-root .MuiTableRow-root .allAnswers {max-width: 300px;min-width: 200px;}


/*******************All List Table CSS*******************/

/* <------------------Stage List Color CSS---------------> */

.stage_wrapper {display: flex;justify-content: space-between;}

/* <------------------Stage List Color CSS---------------> */


 




@media only screen and (max-width: 1199.98px) {

    .hideTop .rolelist_mainwrp{margin-top: 10px;}

    .listing_heading{ margin-top: 10px;}

    .page_heading{ margin-top: 10px;}

 .rolelist_mainwrp  table,  .rolelist_mainwrp tbody,   .rolelist_mainwrp td, .rolelist_mainwrp tr {  display: block;  width: 100%; min-width: inherit!important;
}

.rolelist_mainwrp tr { margin: 10px 0;  border: solid 1px #ccc; }
.contract_slug_wrp .MuiTableRow-root td {padding-left: 57%!important;word-break: break-word;}
.rolelist_mainwrp tr:first-of-type{ margin-top: 0px;}

.rolelist_mainwrp thead{ display: none;}

.rolelist_mainwrp td{ position: relative;  padding-left: 50%!important; }

.rolelist_mainwrp td:before {  content: attr(title); position: absolute; left: 0; padding-left: 10px; font-weight: bold;}

.rolelist_mainwrp td input{ padding: 0px!important;}

.rolelist_mainwrp_Table .MuiTableContainer-root td:last-of-type{ padding-left: 5px!important;}
.userlisttable .MuiTableContainer-root td:last-of-type{ padding-left: 5px!important;}

/*******************All List Table Responsive by Sanket*******************/

.landingPageTable .MuiTable-root .MuiTableRow-root .description, .landingPageTable .MuiTable-root .MuiTableRow-root .name
, .landingPageTable .MuiTable-root .MuiTableRow-root .type, .trainingdata .MuiTable-root .MuiTableRow-root .training_name, 
.trainingdata .MuiTable-root .MuiTableRow-root .training_parent_name, .trainingdata .MuiTable-root .MuiTableRow-root .program_names,
.tableDataQuiz .MuiTable-root .MuiTableRow-root .question, .tableDataQuiz .MuiTable-root .MuiTableRow-root .lesson_names,
.tableDataQuiz .MuiTable-root .MuiTableRow-root .training_name, .tableDataQuiz .MuiTable-root .MuiTableRow-root .allAnswers 
{max-width: inherit;min-height: inherit;}


/*******************All List Table Responsive by Sanket*******************/

}
@media only screen and (max-width:991px) {
.list_search .list_search_item {flex: 1 0 48%;}
.list_search .MuiAutocomplete-root {min-width: inherit !important;}

 .rolelist_mainwrp_Table{ padding: 10px;}

}

@media only screen and (max-width:420.98px) {
    .rolelist_mainwrp td{ position: relative;  padding-left: 10px!important; }
    .rolelist_mainwrp td:before {  position: inherit; padding-left: 0px; padding-bottom:5px; display: block;}
    .lastLogin_Info .loginInfoData .replayicon {right: 0;}
    .lastLogin_Info .MuiTypography-root {font-size: 20px !important;}
    .contract_slug_wrp .MuiTableRow-root td {
        padding-left: 10px !important;
    }
} 

/* --------------Landing Page Management css for image modal ***anindya***--------------- */

.modalBaseBox {
    background-color: #fff;
    border: 10px solid #0569ad!important;
    border: 5px solid var(--modal-border);
    border-radius: 5px;
    margin: 40px auto;
    outline: none;
    padding: 20px;
    position: relative;
    /* width: -webkit-fit-content;
    width: -moz-fit-content; */
    width: fit-content;
}

.modal_icon_button {
    background-color: #0569ad!important;
    /* background-color: var(--modal-close-bg)!important; */
    border: 0;
    border-radius: 50%!important;
    height: 30px;
    opacity: 1;
    padding: 5px!important;
    position: absolute!important;
    right: -14px;
    top: -17px;
    width: 30px;
}

.modal_icon_button svg {
    fill: #fff;
}
.img_title {
    background: #003166;
    /* border: 1px solid #000; */
    border-radius: 5px;
    color: #fff;
    font-size:35px;
    font-weight: var(--main-medium);
    font-weight: 500;
    margin: 15px 0px;
    margin-bottom: 0px;
    padding: 10px 5px;
    text-align: center;
}


